import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Box, Typography } from '@material-ui/core';
import Dialog from '~components/Dialog';
import MessageBox from '~components/forms/MessageBox';
import LoadingSpinner from '~components/LoadingSpinner';
import { TextInput } from '~components/form-inputs';
import { ButtonSubmit } from '~components/form-buttons';
import RfAndFriendsImage from '~images/popups/rf-and-friends.png';
import ButtonClosePopup from '../components/ButtonClosePopup';

const useStyles = makeStyles(() => ({
  dialogStyle: {
    '& > div > .MuiPaper-rounded': {
      backgroundColor: 'rgba(255, 255, 255, 0)'
    },
    '& > .MuiBackdrop-root': {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      backdropFilter: 'blur(3px)'
    }
  },
  rbFormAndFriends: {
    position: 'absolute',
    zIndex: -1,
    width: '100%',
    height: 'auto',
    top: '-100%',
    left: '0',
    transform: 'translateY(42px)'
  },
  vectorImage: {
    position: 'absolute',
    zIndex: -1,
    left: '-25px'
  },
  title: {
    letterSpacing: '0em',
    zIndex: 2,
    marginBottom: '20px'
  },
  subTitle: {
    zIndex: 2,
    marginBottom: '20px',
    maxWidth: '380px'
  },
  closeButton: {
    position: 'absolute',
    top: '0',
    right: '15px',
    zIndex: 20
  },
  contentContainer: {
    backgroundColor: 'white',
    width: '655px',
    height: 'auto',
    position: 'relative',
    borderRadius: '0 0 50px 50px',
    marginTop: '150px',
    padding: '0 20px 20px',
    boxSizing: 'border-box',

    '&:after': {
      content: '""',
      position: 'absolute',
      top: '-75px',
      left: '0',
      width: '100%',
      borderRadius: '50%',
      display: 'block',
      backgroundColor: 'white',
      height: '150px',
      zIndex: -1
    }
  }
}));

const NewsletterSignupPopup = ({ state, registerCta, isOpen, handleClose }) => {
  const classes = useStyles();
  const tags = 'book-like-no-other';
  const { register, handleSubmit } = useForm({
    defaultValues: {
      tags,
      username: ''
    }
  });
  // @see https://react-hook-form.com/api/useform
  register('tags', { value: tags });

  const { isLoading, isError, data: ctaRegisterData, error } = state;

  const isFinishedSubmittingEmail = ctaRegisterData;

  const emailInput = isFinishedSubmittingEmail ? null : (
    <TextInput
      label="Your Email Address"
      type="email"
      name="username"
      style={{ maxWidth: '50%', marginRight: '20px', padding: 0 }}
      inputRef={register}
      margin="0"
    />
  );

  const submitButton = isFinishedSubmittingEmail ? (
    <Typography>Thanks for signing up!</Typography>
  ) : (
    <ButtonSubmit disabled={isLoading} style={{ backgroundColor: '#0068FF' }}>
      Register
    </ButtonSubmit>
  );

  return (
    <Dialog isOpen={isOpen} className={classes.dialogStyle} id="banner_newsletter_subscription">
      <form onSubmit={handleSubmit(registerCta)}>
        <Box className={classes.contentContainer}>
          <img
            src={RfAndFriendsImage}
            alt="Rabbi Fohrman and friends"
            className={classes.rbFormAndFriends}
          />
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Typography variant="h3" align="center" className={classes.title} gutterBottom>
              Discover a new way of bringing Torah to life
            </Typography>
            <Typography
              variant="subtitle1"
              align="center"
              className={classes.subTitle}
              gutterBottom
            >
              Get Aleph Beta emails that keep you informed, curious and inspired.
            </Typography>
          </Box>

          <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
            {isLoading ? <LoadingSpinner /> : emailInput}
            <Box textAlign="center" py={2}>
              {submitButton}
            </Box>
          </Box>
          <ButtonClosePopup handleClose={handleClose} className={classes.closeButton} />
        </Box>
      </form>
      {isError && <MessageBox text={error} isError />}
    </Dialog>
  );
};

NewsletterSignupPopup.propTypes = {
  state: PropTypes.shape({
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    error: PropTypes.string,
    data: PropTypes.shape({})
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  registerCta: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default NewsletterSignupPopup;
