import React, { useState } from 'react';
import { useCookie } from '@use-hook/use-cookie';

import { useLocation } from '@reach/router';

import { useUserContext } from '~context/UserContext/UserSessionContext';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { useCtaRegistration } from '~hooks/useAuth';
import NewsletterSignupPopup from './NewsletterSignupPopup';

const NewsletterSignupPopupContainer = () => {
  const location = useLocation();
  const { pathname } = location;

  const { session } = useUserContext();
  const { isLoggedIn, isUserStateLoading } = session;

  const [regState, registerCta] = useCtaRegistration();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [isOpen, setIsOpen] = useState(false);

  const [isNewsletterSignupPopupHidden, setIsNewsletterSignupPopupHidden] = useCookie(
    `hide-newsletter-popup`,
    false
  );

  if (
    !pathname.includes('/podcasts/book-like-no-other') ||
    isUserStateLoading ||
    isNewsletterSignupPopupHidden ||
    isLoggedIn ||
    isMobile
  ) {
    return null;
  }
  if (!isOpen) {
    setTimeout(() => setIsOpen(true), 5000);
  }

  const handleClose = () => {
    setIsOpen(false);
    setIsNewsletterSignupPopupHidden(true, { expires: 1 });
  };

  return (
    <NewsletterSignupPopup
      isMobile={isMobile}
      state={regState}
      registerCta={registerCta}
      isOpen={isOpen}
      handleClose={handleClose}
    />
  );
};

export default NewsletterSignupPopupContainer;
